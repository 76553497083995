import Home from "./components/Home.vue";
import DesarrolloWeb from "./components/DesarrolloWeb.vue";
import EShop from "./components/EShop.vue";
import Marketplace from "./components/Marketplace.vue";
import Logistica from "./components/Logistica.vue";
import Software from "./components/Software.vue";
import DivisionGobierno from "./components/DivisionGobierno.vue";
import Consultoria from "./components/Consultoria.vue";
import BolsaTrabajo from "./components/BolsaTrabajo.vue";
import TerminosYCondiciones from "./components/TerminosYCondiciones.vue";
import Contacto from "./components/Contacto.vue";

const routes = [
    {
      path: '/',
      name: "/home",
      component: Home
    },
    {
      path: '/desarrollo-web',
      name: "/desarrollo-web",
      component: DesarrolloWeb
    },
    {
      path: '/e-shop',
      name: "/e-shop",
      component: EShop
    },
    {
      path: '/marketplace',
      name: "/marketplace",
      component: Marketplace
    },
    {
      path: '/logistica',
      name: "/logistica",
      component: Logistica
    },
    {
      path: '/software',
      name: "/software",
      component: Software
    },
    {
      path: '/division-gobierno',
      name: "/division-gobierno",
      component: DivisionGobierno
    },
    {
      path: '/consultoria',
      name: "/consultoria",
      component: Consultoria
    },
    {
      path: '/bolsa-de-trabajo',
      name: "/bolsa-de-trabajo",
      component: BolsaTrabajo
    },
    {
      path: '/terminos-y-condiciones',
      name: "/terminos-y-condiciones",
      component: TerminosYCondiciones
    },
    {
      path: '/contacto',
      name: "/contacto",
      component: Contacto
    }
  ];

export default routes;