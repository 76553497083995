var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{attrs:{"color":"secondary"}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('img',{attrs:{"src":"/images/Logo-02.png","height":"auto","width":"60%","alt":""}})])],1),_c('v-row',[_c('v-col',{class:[
        _vm.$vuetify.breakpoint.smAndDown
          ? 'd-flex justify-start align-center flex-column'
          : '',
      ],attrs:{"cols":"12","sm":"6","md":"4"}},[_c('h5',{staticClass:"primary--text mx-4"},[_c('a',{staticClass:"uri",attrs:{"href":"/documents/AVISO DE PRIVACIDAD INTEGRAL - PROVEEDORES.docx"}},[_vm._v("Aviso de privacidad")])]),_c('h5',{staticClass:"primary--text mx-4 my-2"},[_c('router-link',{staticClass:"uri",attrs:{"to":"/terminos-y-condiciones"}},[_vm._v("Términos y condiciones")])],1),_c('h5',{staticClass:"primary--text mx-4 my-2"},[_c('router-link',{staticClass:"uri",attrs:{"to":"/bolsa-de-trabajo"}},[_vm._v("Bolsa de trabajo")])],1),_c('h5',{staticClass:"primary--text mx-4 my-2"},[_c('router-link',{staticClass:"uri",attrs:{"to":"/contacto"}},[_vm._v("Contacto")])],1)]),_c('v-col',{class:[
        _vm.$vuetify.breakpoint.smAndDown
          ? 'd-flex justify-start align-center flex-column'
          : '',
      ],attrs:{"cols":"12","sm":"6","md":"4"}},[_c('p',{staticClass:"primary--text my-0"},[_vm._v(" Télefono: 33 2832 6870 "),_c('br'),_vm._v(" Macadamia No. 137 "),_c('br'),_vm._v(" Col. Paraíso, 63038 "),_c('br'),_vm._v(" Tepic, Nayarit, México")])]),_c('v-row',[_c('v-col',{class:[
        _vm.$vuetify.breakpoint.smAndDown
          ? 'd-flex justify-center align-center '
          : '',
      ],attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"px-2",attrs:{"href":"https://www.facebook.com/profile.php?id=100089221374015","rounded":"","color":"primary","outlined":"","target":"_blank"}},[_c('v-icon',{staticClass:"mx-0",attrs:{"left":""}},[_vm._v("mdi-facebook")])],1),_c('v-btn',{staticClass:"px-2 mx-2",attrs:{"href":"https://www.linkedin.com/company/tidingo/","rounded":"","color":"primary","outlined":"","target":"_blank"}},[_c('v-icon',{staticClass:"mx-0",attrs:{"left":""}},[_vm._v("mdi-linkedin")])],1),_c('v-btn',{staticClass:"px-2",attrs:{"href":"https://api.whatsapp.com/send/?phone=523328326870&text=Hola%25Gremti&type=phone_number&app_absent=0","rounded":"","color":"primary","outlined":"","target":"_blank"}},[_c('v-icon',{staticClass:"mx-0",attrs:{"left":""}},[_vm._v("mdi-whatsapp")])],1)],1)],1)],1),_c('v-divider',{attrs:{"color":"white"}}),_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('h4',{staticClass:"my-4 primary--text"},[_vm._v(" © "+_vm._s(_vm.year)+" Tidingo. Todos los derechos reservados. ")]),_c('div',{staticClass:"d-flex justify-space-around"},[_c('v-btn',{attrs:{"text":"","small":"","color":"secondary","href":"/aviso_de_privacidad"}},[_vm._v(" Aviso de privacidad ")]),_c('v-btn',{attrs:{"text":"","small":"","color":"secondary","href":"/tyc"}},[_vm._v("Términos y condiciones")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }