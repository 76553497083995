<template>
   <div>
        <v-row>
            <v-img 
                v-if="!$vuetify.breakpoint.smAndDown"
                height="100vh" 
                src="/images/headers/Consultoria.png">
            </v-img>
            <v-img 
                v-else
                height="100vh" 
                src="/images/home/firstBannerRespo/Banners header-22.png">
            </v-img>    
        </v-row>
     
      <v-container fluid>
          <v-row>
              <v-col cols="12" sm="12" md="6" lg="7" class="primary d-flex justify-center align-center flex-column">
                  <h2 class="text-start secondary--text px-4">
                    ¿Tienes una idea de negocio? ¿Quieres potenciar tu operación?
                    Tenemos el mejor equipo de consultoría integral y multidisciplinario,
                    que te ayudará en el proceso de desarrollo de nuevas actualizaciones,
                    implementaciones y requerimientos que tu proyecto demande.
                  </h2>
                  <div class="mt-6 d-flex flex-row">
                    <v-row>
                        <v-col 
                            cols="12" xl="12" lg="12" md="12" sm="12" xs="12" 
                            class="d-flex justify-center align-center flex-column">
                            <h3 class="text-center secondary--text">Más información.</h3>
                            <router-link class="uri" to="/contacto">
                                <v-btn x-large rounded outlined color="secondary"
                                    class="mb-md-0 mr-md-6 mb-sm-6 mb-6 mr-0">
                                    <v-icon left>mdi-account</v-icon>Contacta un asesor
                                </v-btn>
                            </router-link>
                        </v-col>
                    </v-row>
                  </div>
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="5">
                <v-img
                    src="/images/modulo2/catalogo consultoria.png"
                ></v-img>
              </v-col>
          </v-row>
      </v-container>
      <v-container fluid-lg class="my-6" grid-list-lg>
          <v-row>
              <v-col cols="12">
                  <h1 class="text-center secondary--text">¿Qué nos hace diferentes?</h1>
              </v-col>
              <v-row>
                  <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                      <v-card shaped elevation="1" :max-width="$vuetify.breakpoint.smAndDown ? '90vw' : ''">
                          <v-img src="/images/innovacion.png" cover></v-img>
                          <v-card-title class="secondary--text">
                              Innovación
                          </v-card-title>
                          <v-card-text class="text-start secondary--text">
                                Con un enfoque puntual en creatividad 
                                junto a la technología desarrollamos,
                                logramos posicionar innovaciones en 
                                procesos y operaciones demantantes, 
                                resultando así un monitoreo eficiente 
                                y claro.
                          </v-card-text>
                      </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                      <v-card shaped elevation="1" :max-width="$vuetify.breakpoint.smAndDown ? '90vw' : ''">
                          <v-img src="/images/soporte_tecnico.png" cover></v-img>
                          <v-card-title class="secondary--text">
                              Soporte técnico
                          </v-card-title>
                          <v-card-text class="text-start secondary--text">
                                Contamos con un equipo destinado
                                a la resolución inmediata de dificultades
                                técnicas, actualizaciones y asistencia
                                en dudas y mejoras constantes.
                          </v-card-text>
                      </v-card>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" class="d-flex justify-center">
                      <v-card shaped elevation="1" :max-width="$vuetify.breakpoint.smAndDown ? '90vw' : ''">
                          <v-img src="/images/potencia_tu_operacion.png" cover></v-img>
                          <v-card-title class="secondary--text">
                              Potencia tu operación
                          </v-card-title>
                          <v-card-text class="text-start secondary--text">
                                Nuestro plan integral de productos,
                                facilita a los usuarios expandir sus
                                negocios y operaciones con la red de 
                                nuestro catálogo de tecnologías.
                                Complementa tu producto y lleva al
                                máximo potencial tu empresa.
                          </v-card-text>
                      </v-card>
                  </v-col>
              </v-row>
          </v-row>
      </v-container>
      <Formulario></Formulario>
    </div>
</template>

<script>
import Formulario from "./Formulario.vue";
export default {
  name: 'Consultoria',
  components: {
    Formulario
  },
  props: {
  },
  data: () => ({
    ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
  })
}
</script>