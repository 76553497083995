<template>
  <div>
    <div class="spinner-wrapper" id="spinner-wrapper">
      <img src="/images/Logo-02.png" alt="" class="logo-loader" />
      <div class="spinner">
        <div class="dot1"></div>
        <div class="dot2"></div>
      </div>
    </div>
    <v-app id="app">
      <Header />
      <v-main>
        <router-view></router-view>
      </v-main>
      <Footer />
    </v-app>
  </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",

  components: {
    Header,
    Footer,
  },

  data: () => ({}),
};

document.addEventListener(
  "DOMContentLoaded",
  function () {
    var op = 1; // initial opacity
    var element = document.getElementById("spinner-wrapper");
    element.style.display = "block";
    var timer = setInterval(function () {
      if (op <= 0) {
        clearInterval(timer);
        element.remove();
      }
      element.style.opacity = op;
      element.style.filter = "alpha(opacity=" + op * 100 + ")";
      op -= 0.03;
    }, 10);
  },
  false
);
</script>
