<template>
  <v-footer color="secondary">
    <v-container>
      <v-row>
        <v-col  cols="12" sm="12" md="4">
          <img src="/images/Logo-02.png" height="auto" width="60%" alt="" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" md="4"
        :class="[
          $vuetify.breakpoint.smAndDown
            ? 'd-flex justify-start align-center flex-column'
            : '',
        ]">
          <h5 class="primary--text mx-4"><a class="uri" href="/documents/AVISO DE PRIVACIDAD INTEGRAL - PROVEEDORES.docx">Aviso de privacidad</a></h5>
          <h5 class="primary--text mx-4 my-2"><router-link class="uri" to="/terminos-y-condiciones">Términos y condiciones</router-link></h5>
          <h5 class="primary--text mx-4 my-2"><router-link class="uri" to="/bolsa-de-trabajo">Bolsa de trabajo</router-link></h5>
          <h5 class="primary--text mx-4 my-2 "><router-link class="uri" to="/contacto">Contacto</router-link></h5>
        </v-col>
        <v-col cols="12" sm="6" md="4"
        :class="[
          $vuetify.breakpoint.smAndDown
            ? 'd-flex justify-start align-center flex-column'
            : '',
        ]">
          <p class="primary--text my-0">
                                 Télefono: 33 2832 6870 <br /> 
                                 Macadamia No. 137 <br />
                                 Col. Paraíso, 63038 <br />
                                 Tepic, Nayarit, México</p>
        </v-col>
        <v-row>
          <v-col cols="12" :class="[
          $vuetify.breakpoint.smAndDown
            ? 'd-flex justify-center align-center '
            : '',
        ]">
              <v-btn
                href="https://www.facebook.com/profile.php?id=100089221374015"
                rounded 
                color="primary"
                outlined
                target="_blank"
                class="px-2 "
              >
              <v-icon left class="mx-0">mdi-facebook</v-icon>
              </v-btn>
              <v-btn
                href="https://www.linkedin.com/company/tidingo/"
                rounded 
                color="primary"
                outlined
                target="_blank"
                class="px-2 mx-2"
              >
              <v-icon left class="mx-0">mdi-linkedin</v-icon>
              </v-btn>
              <v-btn
                href="https://api.whatsapp.com/send/?phone=523328326870&text=Hola%25Gremti&type=phone_number&app_absent=0"
                rounded 
                color="primary"
                outlined
                target="_blank"
                class="px-2"
              >
              <v-icon left  class="mx-0">mdi-whatsapp</v-icon>
              </v-btn>
            </v-col>
        </v-row>
      </v-row>
      <v-divider color="white"></v-divider>
      <div class="d-flex flex-column justify-center align-center">
        <h4 class="my-4 primary--text">
          © {{ year }} Tidingo. Todos los derechos reservados.
        </h4>
        <div class="d-flex justify-space-around">
          <v-btn
            text
            small
            color="secondary"
            href="/aviso_de_privacidad"
          >
            Aviso de privacidad
          </v-btn>
          <v-btn text small color="secondary" href="/tyc"
            >Términos y condiciones</v-btn
          >
        </div>
      </div>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  props: {},
  data: () => ({
    year: new Date().getFullYear(),
  }),
};
</script>

<style scoped>
  .uri {
    text-decoration: none;
  }
</style>
