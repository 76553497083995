<template>
    <div>
        <h1 class="secondary--text mx-6 my-16 text-center">
            Términos y condiciones
        </h1>
        <p class="secondary--text mx-12">Los presentes términos y condiciones regulan el software, así como el uso de todos los servicios que ofrece Grupo Empresarial Tidingo S.A. de C.V. a quien en lo sucesivo se le denominara como “LA APLICACIÓN”; los servicios que ofrece “LA APLICACIÓN”, va dirigido a los usuarios de Smartphones, incluyendo pero no limitando, Iphones y Androids.</p>
        <p class="secondary--text mx-12">El uso es totalmente voluntario y se atribuye a quien lo utiliza, de tal manera que “LA APLICACIÓN” recomienda leer los Términos y Condiciones con atención, toda vez que el uso de la misma, indica que se acepta sin ningún tipo de reserva el contenido de los presentes Términos y Condiciones y el aviso de privacidad; así mismo, “LA APLICACIÓN” se reserva el derecho para que en cualquier momento y sin previo aviso, ya sea por motivos técnicos, de seguridad, control, mantenimiento, fallos de suministro eléctrico o por cualquier otra causa, interrumpa, modifique y/o, altere el contenido y/o acceso de la misma.</p>
        <p class="secondary--text mx-12">Es importante señalar, que algunos de los servicios que ofrece “LA APLICACIÓN”, pueden contener Términos y Condiciones específicos del servicio que se requiera por el usuario y que los mismos son adicionales a los que aquí se precisan.</p>
        <p class="secondary--text mx-12">Objeto:</p>
        <p class="secondary--text mx-12">Toda la información proporcionada a “LA APLICACIÓN” será utilizada con fines de mercadotecnia consistentes en; promoción, divulgación y publicitación de negocios y actividades comerciales, mapeo y localización de establecimientos comerciales y de prestación de servicios.</p>
        <p class="secondary--text mx-12">Por otra parte, hacemos de su conocimiento que sus datos podrán ser transferidos a entidades del mismo grupo de interés de la empresa, nacionales o extranjeras, con el objetivo general de cumplir con las finalidades para las cuales ha proporcionado sus datos.</p>
        <p class="secondary--text mx-12">Generalidades:</p>
        <p class="secondary--text mx-12">Los servicios y la información que se proporciona a través de “LA APLICACIÓN” son propiedad de la misma, así como de cualquiera de sus filiales, afiliada yo licenciatarios.</p>
        <p class="secondary--text mx-12">“LA APLICACIÓN” autoriza el uso de la misma exclusivamente para uso personal más no comercial, sin embargo, el usuario deberá garantizar a la “LA APLICACIÓN” que no empleará esta con la finalidad de cometeré o realizar un acto ilícito, ilegal o que se encuentre prohibido por los Términos y Condiciones.</p>
        <p class="secondary--text mx-12">Todo el contenido de la Aplicación, incluyendo, más no limitando, textos, gráficos, fotografías, logotipos, audio, video, marcas, imágenes, bases de datos, así como el diseño gráfico, código fuente y software, son propiedad de “LA APLICACIÓN”, o de terceros, cuyos derechos quedaron reconocidos anteriormente, no obstante, todo este contenido está sujeto a derechos de propiedad intelectual e industrial protegidos por la legislación nacional e internacional aplicable.</p>
        <p class="secondary--text mx-12">Del usuario:</p>
        <p class="secondary--text mx-12">Queda estrictamente prohibida la utilización, reproducción, copia, distribución, modificación y/o alteración en forma total o parcial utilización de todos los elementos del contenido de “LA APLICACIÓN”.</p>
        <p class="secondary--text mx-12">El usuario de “LA APLICACIÓN” se obliga a usarla de forma diligente, correcta y lícita, conforme a la moral y a las buenas costumbres; así mismo, el usuario responderá por los daños y perjuicios de toda naturaleza que “LA APLICACIÓN” pueda sufrir, directa o indirectamente como consecuencia del incumplimiento de cualquiera de las obligaciones derivadas de estos Términos y Condiciones y/o de los términos y condiciones de algún servicio en particular que se incluya en “LA APLICACIÓN”.</p>
        <p class="secondary--text mx-12">El usuario renuncia y acuerda en liberar de toda responsabilidad, así como de iniciar cualquier acción legal en contra de “LA APLICACIÓN”, derivados de cualquier controversia que surja entre el usuario y otros sitios o websites, marcas, redes sociales y demás incluidos.</p>
        <p class="secondary--text mx-12">De “LA APLICACIÓN”:</p>
        <p class="secondary--text mx-12">“LA APLICACIÓN”, no garantiza ni asume ningún tipo de responsabilidad por los daños y perjuicios sufridos a causa de terceros, o a través de conexiones, vínculos o links de los sitios enlazados, ni tampoco por enlaces con otros sitios que el usuario pueda realizar desde “LA APLICACIÓN”, como tampoco, será responsable por los daños y perjuicios que puedan causarse debido a la presencia de virus o de otros elementos lesivos en los contenidos de la misma, que puedan llegar a producir alteración en los sistemas informáticos así como en los documentos o sistemas almacenados en los mismos.</p>
        <p class="secondary--text mx-12">“LA APLICACIÓN” puede ligar al usuario a otros sitios o websites de Internet o redes sociales, mismos que pueden contener información o material que pudiera considerarse como inadecuado u ofensivo. Dichos sitios o websites no se encuentran bajo el control de “LA APLICACIÓN”, por lo que ésta, no se hará responsable, ni garantizará la seguridad de cualquier información personal que sea proporcionada por el usuario a estos, así mismo, no será responsable de la veracidad, exactitud, legalidad, decencia o de cualquier otro aspecto y/o contenido de los mismos que pudiere agraviar a los usuarios de “LA APLICACIÓN”. La inclusión de los sitios o websites en “LA APLICACIÓN” no genera asociación o vinculación entre ésta y los propietarios de aquellos.</p>
        <p class="secondary--text mx-12">“LA APLICACIÓN” no garantiza al usuario la satisfacción de los productos o servicios que el usuario adquiera o pudiera adquirir en cualquiera de los sitios o websites incluidos por parte de terceros dentro de la misma, siendo responsabilidad de sus titulares.</p>
        <p class="secondary--text mx-12">Costos:</p>
        <p class="secondary--text mx-12">El usuario asume el costo entero de todo mantenimiento, reparación o corrección necesaria en caso de que se presente cualquier pérdida o daño que sufra el usuario por uso de “LA APLICACIÓN”.</p>
        <p class="secondary--text mx-12">El usuario podrá pagar en efectivo o con cargo a su tarjeta por los servicios prestados, esto de conformidad a las tarifas que se encontraran vigentes y visibles en “LA APLICACIÓN” al contratar el servicio.</p>
        <p class="secondary--text mx-12">El usuario se compromete a sacar en paz y salvo a “LA APLICACIÓN”, sus filiales, afiliada y/o licenciatarios de cualquier daño que pudiera sufrir por el uso incorrecto o por el incumplimiento de cualquiera de los Términos y Condiciones aquí descritos, así mismo, el usuario conviene en rembolsar a “LA APLICACIÓN”, cualquier gasto o erogación que haya realizado por dichos conceptos, incluyendo gastos y/o honorarios de abogados.</p>
        <p class="secondary--text mx-12">Protección de datos:</p>
        <p class="secondary--text mx-12">Los datos personales, así como toda la información que fue proporcionada por el usuario, serán utilizados exclusivamente para el fin que fueron proporcionados y serán resguardados por la “LA APLICACIÓN”.</p>
        <p class="secondary--text mx-12">Ley aplicable y jurisdicción:</p>
        <p class="secondary--text mx-12">El usuario se obliga a cumplir con las leyes federales, estatales o municipales que le sean aplicables. El usuario se obliga a cumplir con todas las leyes aplicables al uso de la Aplicación.</p>
        <p class="secondary--text mx-12">Para la interpretación y el cumplimiento de los presentes Términos y Condiciones, las partes se someten a la competencia de los juzgados del fuero común del Estado de Nayarit, renunciando desde este momento a cualquier otro fuero que les pudiera corresponder de acuerdo a sus domicilios presentes o futuros.</p>
        <br />
        <p class="secondary--text mx-12">Para el caso que se quiera realizar algún comentario, sugerencia y/o queja sobre la “LA APLICACIÓN”, se agradece enviarla al correo electrónico anteriormente señalado.</p>
    
    </div>
</template>

<script>
export default {
  name: 'TerminosYCondiciones',
  props: {
  },
  data: () => ({
    ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
  })
}
</script>