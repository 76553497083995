<template>
  <v-container>
  <v-app-bar color="primary" dark elevate-on-scroll fixed>
    <v-app-bar-nav-icon 
      color="secondary" 
      class="hidden-sm-and-up"
      @click="drawer = true"></v-app-bar-nav-icon>
    <v-toolbar-title>
      <router-link to="/">
        <v-img
          src="/images/Logo-01.png"
          alt
          height="100"
          width="150"
          contain
        ></v-img>
      </router-link>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu offset-y class="hidden-xs-only">
      <template v-slot:activator="{ on }">
        <v-btn class="hidden-xs-only" color="secondary" text small v-on="on"
          >Productos</v-btn
        >
      </template>
      <v-list class="hidden-xs-only">
        <v-list-item v-for="(item, index) in items" :key="index" class="pa-0">
          <router-link
            class=" px-2 secondary--text body-1"
            style="text-decoration: none; width: 100%"
            :to="item.link"
            >
            <v-list-item-icon>
                <v-img width="20" height="20" class="mx-2" :src="item.icon"></v-img>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-icon>
          </router-link>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
  <v-navigation-drawer fixed v-model="drawer" temporary>
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="secondary--text text--accent-4"
        >
          
          <v-list-item v-for="(item, index) in items" :key="index" class="pa-0">
            <router-link
            class="py-2 px-5 secondary--text body-1"
            style="text-decoration: none; width: 100%"
            :to="item.link"
            >
              <v-list-item-icon>
                <v-img width="20" height="20" class="mx-2" :src="item.icon"></v-img>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-icon>
            </router-link>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
export default {
  name: "Header",
  props: {},
  data: () => ({
    items: [
      {
        title: "Desarrollo web",
        link: "/desarrollo-web",
        icon: "/images/icons/web.png"
      },
      {
        title: "Software",
        link: "/software",
        icon: "/images/icons/software.png"
      },
      {
        title: "Logística",
        link: "/logistica",
        icon: "/images/icons/logistica.png"
      },
      {
        title: "División gobierno",
        link: "/division-gobierno",
        icon: "/images/icons/division.png"
      },
      {
        title: "Consultoría",
        link: "/consultoria",
        icon: "/images/icons/consultoria.png"
      },
      {
        title: "Marketplace",
        link: "/marketplace",
        icon: "/images/icons/marketplace.png"
      },
      {
        title: "Tidingo E-Shop",
        link: "/e-shop",
        icon: "/images/icons/eshop.png"
      },
    ],
    drawer: false,
    group: null,
  }),
};
</script>
