import "@mdi/font/css/materialdesignicons.css";

import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import langEs from "vuetify/es5/locale/es";

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: "mdi" // default - only for display purposes
    },
    theme: {
        themes: {
            light: {
                primary: "#F9F9F9", //EF233C
                secondary: "#192A67",
                accent: "#00CFB5",
                error: "#F95E7D",
                greycard: "#F2F2F2",
                success: "#128D16"
            }
        }
    },
    lang: {
        locales: {
            es: langEs
        },
        current: "es"
    }
};

export default new Vuetify(opts);
