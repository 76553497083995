<template>
    <Formulario></Formulario>
</template>

<script>
import Formulario from "./Formulario.vue";
export default {
  name: 'Contacto',
  components: {
    Formulario
  },
  props: {
  },
  data: () => ({
    ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
  })
}
</script>