import Vue from 'vue'
import App from './App.vue'
import vuetify from "./plugins/vuetify"; // path to vuetify export
import VueRouter from "vue-router";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "history"
});

Vue.use(vuetify);
Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
