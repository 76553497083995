<template>
    <v-container fluid class="py-0">
          <v-row>
              <v-col cols="12" sm="12" md="6" lg="5" class="image-contact">
              </v-col>
              <v-col cols="12" sm="12" md="6" lg="7" class="accent d-flex justify-center align-center flex-column">
                  <v-card shaped class="ma-0 ma-sm-0 ma-md-6 ma-lg-8">
                      <v-card-title>
                          <h3 class="text-center accent--text my-2 break-word">
                              ¿Tienes alguna duda?
                          </h3>
                          <h5 class="text-center grey--text my-2 break-word">
                              Contáctanos llenando el siguiente formulario
                          </h5>
                      </v-card-title>
                      <v-card-text>
                          <form method="post" action="/emailcontacto.php" data-parsley-validate>
                              <v-text-field filled shaped type="text" id="name" name="name" label="¿Cúal es tu nombre?"
                                  required>
                              </v-text-field>
                              <v-text-field filled shaped type="text" id="email" name="email" label="¿Cúal es tu email?"
                                  required>
                              </v-text-field>
                              
                              <v-textarea filled shaped rows="2" id="subject" name="subject" required
                                  label="¿Puedes describir a detalle tu duda?"></v-textarea>
                              <div class="form-group text-center">
                                  <v-btn large color="secondary" rounded type="submit">Enviar mensaje
                                      <v-icon right>mdi-send</v-icon>
                                  </v-btn>
                              </div>
                          </form>
                      </v-card-text>
                  </v-card>
              </v-col>
          </v-row>
      </v-container>
</template>

<script>
export default {
  name: 'Formulario',
  props: {
  },
  data: () => ({
    ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
  })
}
</script>