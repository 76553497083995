<template>
  <div>
    <v-row>
      <v-carousel
        cycle
        height="100vh"
        hide-delimiter-background
        show-arrows-on-hover
        :interval="10000"
        progress
      >
        <v-carousel-item v-for="item in firstBanner" :key="item.id">
          <router-link :to="item.uri">
            <v-img
              height="100vh"
              :src="item.uriImg"
              v-if="!$vuetify.breakpoint.smAndDown"
            >
            </v-img>
            <v-img
              v-else
              height="100vh"
              :src="item.uriImgResponsive" 
            >
            </v-img>
          </router-link>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-container class="mt-12 px-6" grid-list-xl>
      <v-row>
        <v-col cols="12">
          <h1 class="text-center secondary--text">
            Conoce nuestra nueva imagen
          </h1>
        </v-col>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-card
              height="100%"
              shaped
              elevation="1"
              class="d-flex justify-start align-center flex-column"
            >
              <v-card-text
                class="d-flex justify-center align-center flex-column"
              >
                <v-avatar color="secondary" :size="60" class="mb-4">
                  <v-icon :size="42" color="white"
                    >mdi-lightbulb-on-outline
                  </v-icon>
                </v-avatar>
                <h4 class="mb-4">Innovación</h4>
                <p class="grey--text text--darken-2 text-start">
                  Evolucionamos para ofrecerte nuevas formas de control para tu
                  negocio; mejoras en administración, soporte y acceso
                  herramientas que potenciarán tu negocio a nuevos límites.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-card
              height="100%"
              shaped
              elevation="1"
              class="d-flex justify-center align-center flex-column"
            >
              <v-card-text
                class="d-flex justify-center align-center flex-column"
              >
                <v-avatar color="secondary" :size="60" class="mb-4">
                  <v-icon :size="42" color="white"> mdi-chart-line </v-icon>
                </v-avatar>
                <h4 class="mb-4">Cultura laboral</h4>
                <p class="grey--text text--darken-2 text-start">
                  Empresa 100% mexicana; Incentivamos el emprendimiento, la
                  economía y el talento tanto de nuestros colaboradores, socios
                  y alianzas comerciales, con creatividad y competencias justas.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-card
              height="100%"
              shaped
              elevation="1"
              class="d-flex justify-center align-center flex-column"
            >
              <v-card-text
                class="d-flex justify-center align-center flex-column"
              >
                <v-avatar color="secondary" :size="60" class="mb-4">
                  <v-icon :size="42" color="white"> mdi-account-star </v-icon>
                </v-avatar>
                <h4 class="mb-4">Mejoramos tu experiencia</h4>
                <p class="grey--text text--darken-2 text-start">
                  Trabajamos día a día para ofrecerte una mejor experiencia al
                  usar nuestros productos y servicios; te garantizamos accesos
                  fáciles, diseño intuitivo, herramientas novedosas y resultados
                  asombrosos.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-container class="mt-12 px-6" grid-list-xl>
      <v-row>
        <v-col cols="12">
          <h1 class="text-center secondary--text">
            Misión, objetivo y valores
          </h1>
        </v-col>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-card
              height="100%"
              shaped
              elevation="1"
              class="d-flex justify-start align-center flex-column"
            >
              <v-card-text
                class="d-flex justify-center align-center flex-column"
              >
                <v-avatar color="secondary" :size="60" class="mb-4">
                  <v-icon :size="42" color="white">mdi-check </v-icon>
                </v-avatar>
                <h4 class="mb-4">Misión</h4>
                <p class="grey--text text--darken-2 text-start">
                  En TIDIGNO tenemos la misión de desarrollar tecnologías web y
                  móviles, las cuales generan nuevas oportunidades de expansión,
                  crecimiento y bastante confiabilidad a nuestros colaboradores,
                  socios, proveedores, clientes e inversionistas así como
                  modernidad a las empresas y Gobiernos en las que implementamos
                  nuestros desarrollos.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-card
              height="100%"
              shaped
              elevation="1"
              class="d-flex justify-start align-center flex-column"
            >
              <v-card-text
                class="d-flex justify-center align-center flex-column"
              >
                <v-avatar color="secondary" :size="60" class="mb-4">
                  <v-icon :size="42" color="white">mdi-bullseye-arrow </v-icon>
                </v-avatar>
                <h4 class="mb-4">Objetivo</h4>
                <p class="grey--text text--darken-2 text-start">
                  Buscamos tener presencia en todo Mexico y otros países,
                  replicando nuestro modelo de negocio innovador y sustentable,
                  generando crecimiento a nuestros colaboradores, socios,
                  proveedores, clientes e inversionistas.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="4">
            <v-card
              height="100%"
              shaped
              elevation="1"
              class="d-flex justify-start align-center flex-column"
            >
              <v-card-text
                class="d-flex justify-center align-center flex-column"
              >
                <v-avatar color="secondary" :size="60" class="mb-4">
                  <v-icon :size="42" color="white"> mdi-creation </v-icon>
                </v-avatar>
                <h4 class="mb-4">Valores</h4>
                <ul class="grey--text text--darken-2 text-start">
                  <li>Organización</li>
                  <li>Responsabilidad</li>
                  <li>Puntualidad</li>
                  <li>Creatividad</li>
                  <li>Innovación</li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-container>

    <v-row>
      <v-carousel
        cycle
        height="100vh"
        hide-delimiter-background
        show-arrows-on-hover
        :interval="10000"
        progress
      >
        <v-carousel-item v-for="item in secondBanner" :key="item.id">
          <v-img
            height="100vh"
            :src="item.uriImg"
            v-if="!$vuetify.breakpoint.smAndDown"
            >
            </v-img>
            <v-img
              v-else
              height="100vh"
              :src="item.uriImgResponsive" 
            >
            </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-row>

    <v-container class="mt-12 px-6" grid-list-xl>
      <v-row>
        <v-col cols="12">
          <h1 class="text-center secondary--text">
            Catálogo de productos y servicios
          </h1>
        </v-col>
        <v-row>
          <v-col
            cols="12"
            xl="4"
            lg="4"
            md="6"
            sm="6"
            xs="12"
            v-for="item in Catalog"
            :key="item.id"
          >
            <v-card fluid class="fill-height">
              <v-card-text>
                <v-row align="center" justify="center">
                  <v-col
                    xl="8"
                    lg="8"
                    md="8"
                    sm="8"
                    xs="8"
                    class="image-container"
                  >
                    <h4 class="mb-4 secondary--text">{{ item.product }}</h4>
                    <p
                      class="text-start mt-3 pb-8 mr-2"
                      v-html="item.description"
                    ></p>
                    <v-btn
                      text
                      :to="item.uriMoreInfo"
                      color="secondary"
                      class="text-capitalize"
                    >
                      Entrar
                    </v-btn>
                  </v-col>
                  <v-col
                    xl="4"
                    lg="4"
                    md="4"
                    sm="4"
                    xs="4"
                    class="image-container d-flex justify-center align-center"
                  >
                    <v-img
                      :src="item.uriImg"
                      contain
                      max-width="120"
                      min-width="100"
                    ></v-img>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-row>
    </v-container>
    <v-container class="mt-12 px-6" grid-list-xl>
      <v-row>
        <v-col cols="12">
          <h1 class="text-center secondary--text">CLIENTES Y PROYECTOS</h1>
        </v-col>

        <v-col 
          cols="12" 
          xl="6" 
          lg="6" 
          md="12" 
          sm="12" 
          xs="12" 
          align-self="center" 
          :class="[
          $vuetify.breakpoint.xsOnly ? 'd-flex justify-center align-center flex-wrap': '',
        ]">

          <div
            class="imagen"
            style="background-image: url('/images/clientes/tepic.png')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/siapa.png')"
          ></div>
          
          <div
            class="imagen"
            style="background-image: url('/images/clientes/alica.png')"
          ></div>
          
          <div
            class="imagen"
            style="background-image: url('/images/clientes/vizcaya.jpg')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/ciprees.png')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/paquita.jfif')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/simon.png')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/coparmex.png')"
          ></div>
          
          <div
            class="imagen"
            style="background-image: url('/images/clientes/casa.jfif')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/foton2.png')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/kioti.jpg')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/llaossa.jpg')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/iansa.jpg')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/xhkg.png')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/vizcaya_primaria.webp')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/vizcaya_secundaria.webp')"
          ></div>

          <div
            class="imagen"
            style="background-image: url('/images/clientes/vizcaya_prepa.webp')"
          ></div>

        
        </v-col>
        <v-col cols="12" xl="6" lg="6" md="12" sm="12" xs="12" class="image-container">
          <v-container>
            <v-row>
              <v-img
                height="50vh"
                src="/images/Imagen-portafolio.png"
              >
              </v-img>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  props: {},
  data: () => ({
    firstBanner: [
      {
        id: 1,
        uri: "/desarrollo-web",
        uriImg: "/images/home/firstBanner/Banners header_Mesa de trabajo 1.png",
        uriImgResponsive: "/images/home/firstBannerRespo/Banners header-18.png"
      },
      {
        id: 2,
        uri: "/software",
        uriImg:
          "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia.png",
        uriImgResponsive: "/images/home/firstBannerRespo/Banners header-19.png"
      },
      {
        id: 3,
        uri: "/logistica",
        uriImg:
          "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 2.png",
        uriImgResponsive: "/images/home/firstBannerRespo/Banners header-20.png"
      },
      {
        id: 4,
        uri: "/division-gobierno",
        uriImg:
          "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 3.png",
        uriImgResponsive: "/images/home/firstBannerRespo/Banners header-21.png"
      },
      {
        id: 5,
        uri: "/consultoria",
        uriImg:
          "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 4.png",
        uriImgResponsive: "/images/home/firstBannerRespo/Banners header-22.png"
      },
      {
        id: 6,
        uri: "/marketplace",
        uriImg:
          "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 5.png",
        uriImgResponsive: "/images/home/firstBannerRespo/Banners header-23.png"
      },
      {
        id: 7,
        uri: "/e-shop",
        uriImg:
          "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 6.png",
        uriImgResponsive: "/images/home/firstBannerRespo/Banners header-24.png"
      },
      {
        id: 8,
        uri: "",
        uriImg:
          "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 7.png",
        uriImgResponsive: "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 7.png"
      },
      {
        id: 9,
        uri: "",
        uriImg:
          "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 8.png",
        uriImgResponsive: "/images/home/firstBannerRespo/Banners header-25.png"
      },
      {
        id: 10,
        uri: "",
        uriImg:
          "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 9.png",
        uriImgResponsive: "/images/home/firstBanner/Banners header_Mesa de trabajo 1 copia 9.png"
      },
    ],
    secondBanner: [
      {
        id: 1,
        title: "",
        titleFirstButton: "CONTRATA",
        uriFistButton: "",
        uriImg:
          "/images/home/secondBanner/Banner promociones_Mesa_de_trabajo_1_digitaliza.png",
        uriImgResponsive: "/images/home/secondBannerRespo/Banners header-28.png",
      },
      {
        id: 2,
        title: "",
        titleFirstButton: "CONTRATA",
        uriFistButton: "",
        uriImg:
          "/images/home/secondBanner/Banner promociones_Mesa_de_trabajo 1_digitaliza2.png",
        uriImgResponsive: "/images/home/secondBannerRespo/Banners header-26.png",
      },
      {
        id: 3,
        title: "",
        titleFirstButton: "CONTRATA",
        uriFistButton: "",
        uriImg:
          "/images/home/secondBanner/Banner promociones_Mesa_de_trabajo 1_tecnologia.png",
        uriImgResponsive: "/images/home/secondBannerRespo/Banners header-29.png",
      },
      {
        id: 4,
        title: "",
        titleFirstButton: "CONTRATA",
        uriFistButton: "",
        uriImg:
          "/images/home/secondBanner/Banner promociones_Mesa_de_trabajo_1_tecnologia_2.png",
        uriImgResponsive: "/images/home/secondBannerRespo/Banners header-27.png",
      },
    ],
    thirdBanner: [
      {
        id: 1,
        uriImg: "/images/home/thirdBanner/paginaGremti.png",
      },
      {
        id: 2,
        uriImg: "/images/home/thirdBanner/paginaLogistica.png",
      },
      {
        id: 3,
        uriImg: "/images/home/thirdBanner/paginaTidingo.png",
      },
      {
        id: 4,
        uriImg: "/images/home/thirdBanner/paginaTidishop.png",
      },
      {
        id: 5,
        uriImg: "/images/home/thirdBanner/paginaCoparmex.png"
      },
      {
        id: 6,
        uriImg: "/images/home/thirdBanner/paginaSiapa.png"
      },
      {
        id: 7,
        uriImg: "/images/home/thirdBanner/paginaUva.png"
      },
      {
        id: 8,
        uriImg: "/images/home/thirdBanner/paginaLlantasDirectas.png"
      }
    ],
    Catalog: [
      {
        id: 1,
        product: "Desarrollo web",
        description: "Unidad especializada en desarrollo web.",
        uriMoreInfo: "/desarrollo-web",
        uriImg:
          "/images/home/iconos/catalogo productos_Mesa_desarrollo_web.png",
      },
      {
        id: 2,
        product: "Logística",
        description: "Toma el control de <br/>tu operación.",
        uriMoreInfo: "/logistica",
        uriImg: "/images/home/iconos/catalogo productos_Mesa_logistica.png",
      },
      {
        id: 3,
        product: "Marketplace",
        description: "La mejor solución para vender en internet.",
        uriMoreInfo: "/marketplace",
        uriImg: "/images/home/iconos/catalogo productos_Mesa_marketplace.png",
      },
      {
        id: 4,
        product: "Software",
        description: "Digitaliza tu operación.",
        uriMoreInfo: "/software",
        uriImg: "/images/home/iconos/catalogo productos_Mesa_marketplace.png",
      },
      {
        id: 5,
        product: "División gobierno",
        description: "Unidad especializada en operación gubernamental.",
        uriMoreInfo: "/division-gobierno",
        uriImg:
          "/images/home/iconos/catalogo productos_Mesa_division_gobierno.png",
      },
      {
        id: 6,
        product: "Consultoría",
        description: "Desarrollamos tu idea<br /> de negocio.",
        uriMoreInfo: "/consultoria",
        uriImg: "/images/home/iconos/catalogo productos_Mesa_consultoria.png",
      },
      {
        id: 7,
        product: "Tidingo E-Shop",
        description: "Comercializa con la mejor administración.",
        uriMoreInfo: "/e-shop",
        uriImg: "/images/home/iconos/catalogo productos_Mesa_e_shop.png",
      },
    ],
    ecosystem: [
      {
        text: "vuetify-loader",
        href: "https://github.com/vuetifyjs/vuetify-loader",
      },
      {
        text: "github",
        href: "https://github.com/vuetifyjs/vuetify",
      },
      {
        text: "awesome-vuetify",
        href: "https://github.com/vuetifyjs/awesome-vuetify",
      },
    ],
  }),
};
</script>


<style scoped>
.imagen {
  float: left;
  height: 80px;
  width: 80px;
  margin: 6px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  background-position: center;
  border-radius: 50%;
  border: 1px solid black;
}

.imagen:hover {
  cursor: pointer;
  border: 1px solid gray;
}
</style>