<template>
    <div>
        <h1 class="secondary--text mx-6 my-16 text-center">
            Gracias por tu interés en formar parte del equipo TIDINGO.<br/>
            En estos momentos no tenemos posiciones disponibles.
        </h1>
    </div>
</template>

<script>
export default {
  name: 'BolsaTrabajo',
  props: {
  },
  data: () => ({
    ecosystem: [
        {
          text: 'vuetify-loader',
          href: 'https://github.com/vuetifyjs/vuetify-loader',
        },
        {
          text: 'github',
          href: 'https://github.com/vuetifyjs/vuetify',
        },
        {
          text: 'awesome-vuetify',
          href: 'https://github.com/vuetifyjs/awesome-vuetify',
        },
      ],
  })
}
</script>